<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="账单编号" prop="batch_no"></el-table-column>
          <el-table-column
            label="出账月份"
            prop="bill_month"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column label="账期" align="center" width="200">
            <template #default="{ row }">
              {{ row.begin_time }}
              -
              {{ row.end_time }}
            </template>
          </el-table-column>
          <el-table-column label="账期金额" prop="bill_fee" align="right" width="200"></el-table-column>
          <el-table-column label="支付状态" align="center" width="100">
            <template #default="{ row }">
              <el-tag v-if="row.pay_status === 0">未支付</el-tag>
              <el-popover
                v-if="row.pay_status === 1"
                placement="left"
                width="220px"
              >
                <el-descriptions :column="1">
                  <el-descriptions-item label="支付时间：">
                    {{ row.pay_at }}
                  </el-descriptions-item>
                  <el-descriptions-item label="支付方式：">
                    <template v-if="row.pay_type === 1">微信支付</template>
                    <template v-if="row.pay_type === 2">扫码支付</template>
                    <template v-if="row.pay_type === 3">代客支付</template>
                    <template v-if="row.pay_type === 4">对公转账</template>
                  </el-descriptions-item>
                </el-descriptions>
                <template #reference>
                  <el-button type="text" :icon="View">已支付</el-button>
                </template>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="发票状态" align="center" width="100">
            <template #default="{ row }">
              <el-tag v-if="row.invoice_status === -1" type="info">不可开</el-tag>
              <el-tag v-if="row.invoice_status === 0">可开</el-tag>
              <el-tag v-if="row.invoice_status === 1" type="success">已开</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { View } from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import request from '@/hooks/request';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/order/companyBillList', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

onMounted(() => {
  getList();
});
</script>
